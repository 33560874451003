<template>
    <div>
      <v-card>
        <v-tabs v-model="tab" fixed-tabs background-color="green" dark>
          <v-tabs-slider color="#086318"></v-tabs-slider>
            <v-tab :disabled="enableTeam">Assinatura Digital</v-tab>
          
            <v-tab-item>
            <v-card flat>
              <v-card-text>
                <!-- alterei o ref -->
                <v-form @submit.prevent="createDigitalSignature" ref="form">
                  <v-container>
                    <v-row>
                      <v-col class="d-flex" cols="12" sm="4" v-if="(this.$store.state.profile_id == 1)">
                        <v-select 
                        :items="user"
                        :rules="formRules"
                        color="black" 
                        v-model="digitalSignature.user_id"
                        item-value="id"
                        item-text="name"  
                        dense 
                        outlined 
                        label="Usuário do Sistema">
                        </v-select>
                      </v-col>
                      <v-col class="d-flex" cols="12" sm="8">
                        <v-file-input
                        v-model="digitalSignature.file"
                        label="Assinatura Digitalizada"
                        class="borderRadius10"
                        color="black"
                        append-icon="mdi-camera"
                        prepend-icon=""
                        show-size
                        outlined
                        dense>
                        </v-file-input>
                      </v-col>
                    </v-row>
                    <div class="buttons">
                      <v-row>
                        <v-btn class="rounded-lg" type="submit" elevation="0" color="green">
                          <span class="textBtn"> Salvar </span>
                        </v-btn>
                      </v-row>
                    </div>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
            </v-tab-item>
        </v-tabs>
      </v-card>
  
      <v-snackbar absolute background-color="white" color="#104a21" v-model="snackbar">
        {{ message }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Fechar
          </v-btn>
        </template>
    </v-snackbar>
    </div>
  </template>  
  <script>
  
  import axios from "@/axios";
  // import { isArgumentsObject } from "util/types";
  
  export default {
    data() {
      return {
        totalUsers: 0,
        numberOfPages: 0,
        passengers: [],
        loading: true,
        options: {},
        sortBy: "name",
        disabled: true,
        receiverId: null,
        enableIntegrant: false,
        enableTeam: false,
        message: "",
        team_id: null,
        snackbar: false,
        loadingBTN: false,
        exibirMensagem: false,
        messageError: "",
        team: [],
        tab: null,
        user: [],
        unities: [],
        digitalSignature: {
            unity_id: ""
        },
        medicalTeamForm:{
          team_id: "",
        },
        specialties:[],
        medicalTeamTable:[],
        formRules: [
          (v) => !!v || "Este campo é obrigatório",
        ],
      };
    },
    
    created() {
      axios.get("/users").then((response) => {this.user = response.data.data});
    },
  
    watch: {
      sortBy: {
        handler() {
  
        }
      },
  
      options: {
        handler() {
          this.indoDataTable();
        },
      },
      deep: true,
    },
  
    methods: {
  
      //Carrega as coisas ao acessar a tela
      indoDataTable() {
        this.loading = true;
      },

      mounted() {
        this.indoDataTable();
      },

      createDigitalSignature(){
        if(this.$refs.form.validate() == true){
            this.digitalSignature.unity_id = "1"
              let signatureData = new FormData();
                signatureData.append("signature_url", this.digitalSignature.file)
                signatureData.append("unity_id", this.digitalSignature.unity_id)
                signatureData.append("user_id", this.digitalSignature.user_id)
            axios
            .post(`/digital_signatures`, signatureData)
            .then((response) => {
                this.indoDataTable();
                this.message = response.data.message;
                this.snackbar = true;
                this.dialog = false;
                this.digitalSignature.file = "";
                this.digitalSignature.user_id = "";
            })
            .catch((err) => {
                err;
                var error = "";
                Object.values(this.$store.state.errorMessage).map((value) => {
                error = value;
                });

                this.displayError.message = error.toString();
                this.displayError.display = true;
                setTimeout(() => {
                this.displayError.display = false;
                }, 3000);
            });
        }
      }
  }
};
  </script>
  
  <style scoped>
  .header-table {
    display: flex;
    margin-bottom: 20px;
  }
  
  .space {
    width: 5px;
  }
  
  .spacediferent {
    width: 7px;
  }
  
  .space-personalized {
    padding: 15px;
  }
  
  .v-btn:not(.v-btn--round).v-size--default {
    height: 40px !important;
  }
  
  .textBtn {
    font-family: "Poppins";
    color: #ffffff;
  }
  
  .create-user-dialog {
    padding: 20px;
  }
  
  .card {
    padding: 35px;
  }
  
  .banner {
    background: #b3ffab;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right,
        #27ae60,
        #1abc9c);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right,
        #ee106d,
        #e46987);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
    border-radius: 8px;
  
    margin-bottom: 20px;
  
    display: flex;
    align-items: center;
  }
  
  .icon {}
  
  .banner>h4 {
    color: #ffffff;
    padding: 10px;
  }
  
  .cardFooter {
    padding: 0 !important;
  }
  </style>
  